import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { ReactElement } from "react";


interface ComponentProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

export const MobileBlockedModal = ({ open, message, onClose}:
ComponentProps): ReactElement => {
  return (
    <CabModal
      open={open}
      closeIcon
      isAlert
      noFullScreen
      onClose={onClose}
      title='Coming to mobile soon!'
      text={message}
      actionButtons={
        <CabButton
          color={'primary'} 
          onClick={onClose} 
        >
          OK
        </CabButton>
      }
    />
  );
};

export default MobileBlockedModal;